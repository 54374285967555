import axios from "axios";

let BaseApi = axios.create({
    baseURL: process.env.API_URL,
});

let ZendeskApi = axios.create({
    baseURL: process.env.ZENDESK_API_URL,
});

let wordPressApi = axios.create({
    baseURL: process.env.WORDPRESS_API_URL,
});

const Api = (isZendesk = false) => isZendesk ? ZendeskApi : BaseApi;
const ApiWordpress = () => wordPressApi;

export {Api, ApiWordpress};
