import "./style.scss";
import classNames from "classnames";
import { connect } from "react-redux";
import { getLocalAsset } from "Common/url";
import { toggleNavigation, aboutModal } from "Redux/actionCreators";
import { Link } from "react-router-dom";
import { useEffect, useRef } from "react";

const MobileNavigation = ({ toggleNavigationState, toggleNavigation, aboutModal }) => {
    const refElement = useRef(null);

    useEffect(() => {
        const handleClickOutside = event => {
            if (refElement.current && !refElement.current.contains(event.target)) {
                toggleNavigation(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refElement, toggleNavigationState, toggleNavigation]);

    return (
        <aside styleName={classNames("mobilemenu", { "in": toggleNavigationState })}>
            <div styleName="mobilemenu__inner" ref={refElement}>
                <a href="https://www.combatgo.app/" target="_blank" rel="noreferrer" styleName="branding">
                    <img styleName="branding__img" src={getLocalAsset("logo.png")} alt="logo" />
                    <span styleName="branding__name">COMBAT<span styleName="branding__by">GO</span></span>
                </a>
                <ul styleName="mobilemenu__menu">
                    <li>
                        <Link onClick={() => toggleNavigation(false)} to="config">Configure Workout</Link>
                    </li>
                    <li>
                        <Link onClick={() => toggleNavigation(false)} to="combos">Preview Combos</Link>
                    </li>
                    <li>
                        <Link onClick={() => toggleNavigation(false)} to="primer">Technique Primer</Link>
                    </li>
                    <li>
                        <a onClick={() => toggleNavigation(false)} target="_blank" rel="noreferrer" href="https://blog.combatgo.app/#getting-started">Getting Started</a>
                    </li>
                    <li>
                        <a onClick={() => toggleNavigation(false)} rel="noreferrer" href="mailto:mail@combatgo.app">Feedback &amp; Support</a>
                    </li>
                    <li>
                        <span onClick={() => {
                            toggleNavigation(false);
                            aboutModal(true);
                        }}>About</span>
                    </li>
                </ul>
                <div styleName="get-app">
                    <a target="_blank" rel="noreferrer" href="https://www.combatgo.app/apps/" styleName="get-app__text">Get the app</a>
                    <div styleName="get-app__links">
                        <a styleName="get-app__link" target="_blank" rel="noreferrer" href="https://www.facebook.com/combatgoapp">
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 300 300" style={{fill: "#FFFFFF"}}>
                                <g fill="#ffffff" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{mixBlendMode: "normal"}}>
                                    <g transform="scale(13.33333,13.33333)">
                                        <path d="M12,2c-5.523,0 -10,4.477 -10,10c0,5.013 3.693,9.153 8.505,9.876v-7.226h-2.474v-2.629h2.474v-1.749c0,-2.896 1.411,-4.167 3.818,-4.167c1.153,0 1.762,0.085 2.051,0.124v2.294h-1.642c-1.022,0 -1.379,0.969 -1.379,2.061v1.437h2.995l-0.406,2.629h-2.588v7.247c4.881,-0.661 8.646,-4.835 8.646,-9.897c0,-5.523 -4.477,-10 -10,-10z"></path>
                                    </g>
                                </g>
                            </svg>
                        </a>
                        <a styleName="get-app__link" target="_blank" rel="noreferrer" href="https://www.instagram.com/combatgoapp">
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 300 300" style={{fill: "#FFFFFF"}}>
                                <g fill="#ffffff" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{mixBlendMode: "normal"}}>
                                    <g transform="scale(13.33333,13.33333)">
                                        <path d="M8,3c-2.761,0 -5,2.239 -5,5v8c0,2.761 2.239,5 5,5h8c2.761,0 5,-2.239 5,-5v-8c0,-2.761 -2.239,-5 -5,-5zM18,5c0.552,0 1,0.448 1,1c0,0.552 -0.448,1 -1,1c-0.552,0 -1,-0.448 -1,-1c0,-0.552 0.448,-1 1,-1zM12,7c2.761,0 5,2.239 5,5c0,2.761 -2.239,5 -5,5c-2.761,0 -5,-2.239 -5,-5c0,-2.761 2.239,-5 5,-5zM12,9c-1.65685,0 -3,1.34315 -3,3c0,1.65685 1.34315,3 3,3c1.65685,0 3,-1.34315 3,-3c0,-1.65685 -1.34315,-3 -3,-3z"></path>
                                    </g>
                                </g>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
            <a onClick={() => toggleNavigation(!toggleNavigationState)} styleName="mobilemenu__close">&times;</a>
        </aside>
    );
};

const mapDispatchToProps = dispatch => ({
    toggleNavigation: toggle => dispatch(toggleNavigation(toggle)),
    aboutModal: toggle => dispatch(aboutModal(toggle))
});

const mapStateToProps = state => ({
    toggleNavigationState: state.toggleNavigation
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileNavigation);
