import { ABOUT_LOADING, ABOUT_SUCCESS } from "../actionTypes";

const initialState = {
    status: "idle",
    data: null
};

export const aboutModalData = (state = initialState, action) => {
    switch (action.type) {
        case ABOUT_SUCCESS: {
            const { rendered } = action.payload;
            return {
                ...state,
                data: rendered,
                status: "success"
            };
        }
        case ABOUT_LOADING:
            return {
                ...state,
                status: "loading"
            };
        default:
            return state;
    }
};
