import { Api, ApiWordpress } from "./Api";

export const get = async (path, data, isZendesk = false) => {
    const response = await Api(isZendesk).get(path, {
        params: data
    });

    return response;
};

export const getWithWordpress = async (path, data) => {
    const response = await ApiWordpress().get(path, {
        params: data,
        headers: {
            Authorization: "Basic Y29tYmF0Z286dWJJViBiU3F2IGxwUEIgOGpqYiB4c1pYIFhreDI="
        }
    });

    return response;
};

export const post = async (path, data) => {
    const response = await Api().post(path, data);

    return response;
};
